var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "room-host" }, [
    _c(
      "div",
      { staticClass: "room-host__image", style: _vm.hostImageDefault },
      [
        _vm.user && _vm.user.image
          ? _c("img", {
              attrs: { src: _vm.user.image, alt: _vm.user.firstname },
            })
          : _vm._e(),
      ]
    ),
    _c("div", { staticClass: "room-host__bottom" }, [
      _vm.user && _vm.user.firstname
        ? _c("div", { staticClass: "room-host__name" }, [
            _vm._v(" " + _vm._s(_vm.user.firstname) + " "),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }