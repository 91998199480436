import { computed, ref } from "@vue/composition-api"
import _ from "lodash"
import { Game } from "@/types/game"
import { Filters } from "@/store/PreGame"
import { GameType } from "@/entities/GameType"
import { Role } from "@/helpers"

import useStore from "./useStore"
import useViewer from "./useViewer"
import useClientUsers from "./useClientUsers"
import useLobby from "./useLobby"

const UPSIDE_DOWN_MESSAGE =
  "This might significantly slow down the performance. Continue?"

const upsideDown = ref(false)

export default function useLobbyRooms() {
  const { isVideo, actualSelectedGameID } = useLobby()
  const { store } = useStore()
  const { viewer } = useViewer()
  const { onlineUsersGroupedByGameID } = useClientUsers()

  const games = computed<Game[]>(() => store.state.pregame.games)
  const developer = computed(() => store.state["developer"])
  const isHost = computed(() => store.getters["auth/isHost"])
  const filters = computed(() => store.getters["pregame/filters"])

  const userUnlockedGames = computed(
    () => store.getters["pregame/userUnlockedGames"]
  )
  const userPlayedGames = computed(
    () => store.getters["pregame/userPlayedGames"]
  )

  const userGameID = computed(() => {
    return viewer.value ? viewer.value.gameID : null
  })

  const selectedGame = computed(() =>
    actualSelectedGameID.value
      ? games.value.find(game => game.id === actualSelectedGameID.value)
      : undefined
  )

  const predicates = computed(() => {
    const _filters = filters.value
    return {
      round: game => {
        return parseInt(_filters[Filters.Round]) === parseInt(game.round)
      },
      staging: game => {
        return _filters[Filters.Staging] ? !!game.ondeck : false
      },
      deleted: game => {
        return _filters[Filters.Deleted] ? !!game.deletedTimestamp : false
      },
      assigned: game => {
        return game.id === userGameID.value
      },
      type: game => {
        return (
          game.gameType !== GameType.FlashCard &&
          game.gameType !== GameType.Scoreboard &&
          game.gameType !== GameType.Image
        )
      },
      ended: game => {
        return _filters[Filters.Ended] ? !!game.endTimestamp : false
      },
      inactive: game => {
        return !!game.deactivate
      },
      escapeRoom: game => {
        return !!game.escapeRoom
      }
    }
  })

  const filteredGames = computed<Game[]>(() => {
    if (developer.value) {
      return games.value
    } else if (isHost.value) {
      const _predicates = predicates.value
      const usersByGameID = upsideDown.value
        ? onlineUsersGroupedByGameID.value
        : {}
      return games.value.filter(game => {
        return (
          _predicates.type(game) &&
          (filters.value[Filters.Round]
            ? !_predicates.deleted(game) &&
              !_predicates.staging(game) &&
              _predicates.round(game)
            : _predicates.assigned(game) ||
              (!_predicates.deleted(game) && !_predicates.staging(game)) ||
              (upsideDown.value &&
                usersByGameID[game.id]?.some(user => user.role !== Role.Host)))
        )
      })
    } else {
      const _predicates = predicates.value
      return games.value.filter(game => {
        return (
          _predicates.type(game) &&
          (_predicates.assigned(game) ||
            (!_predicates.ended(game) &&
              !_predicates.deleted(game) &&
              !_predicates.inactive(game) &&
              !_predicates.staging(game)))
        )
      })
    }
  })

  const sortedGames = computed<Game[]>(() => {
    if (isHost) {
      return _.chain(filteredGames.value)
        .orderBy("startTimestamp", "desc")
        .sortBy(game => game.externalName || game.name || "")
        .sortBy(game => !!game.started)
        .sortBy(game => !!game.endTimestamp)
        .sortBy(game => !!game.escapeRoom)
        .sortBy(game => game.id !== userGameID.value)
        .sortBy(game => !game.defaultKeynote)
        .value()
    } else {
      return _.chain(filteredGames.value)
        .orderBy("startTimestamp", "desc")
        .sortBy(game => game.externalName || game.name || "")
        .sortBy(game => !!game.started)
        .sortBy(game => !!game.endTimestamp)
        .sortBy(game => isRoomVisitedByUser(game))
        .sortBy(game => !!game.escapeRoom)
        .orderBy(game => isRoomUnlockedByUser(game), "desc")
        .sortBy(game => game.id !== userGameID.value)
        .sortBy(game => !game.defaultKeynote)
        .value()
    }
  })

  const hasDiyRooms = computed(() => {
    return diyRooms.value.length
  })

  const hasVideoRooms = computed(() => {
    return videoRooms.value.length
  })

  const hasStandardRooms = computed(() => {
    return standardRooms.value.length
  })

  const videoRooms = computed(() => {
    return sortedGames.value.filter(game => isVideo(game))
  })
  const diyRooms = computed(() => {
    return sortedGames.value.filter(game => game.diy)
  })
  const standardRooms = computed(() => {
    return sortedGames.value.filter(game => !isVideo(game) && !game?.diy)
  })

  function toggleUpsideDown() {
    if (!upsideDown.value) {
      if (!confirm(UPSIDE_DOWN_MESSAGE)) return
    }

    upsideDown.value = !upsideDown.value
  }

  function isRoomUnlockedByUser(game: Game) {
    const { originalGameID, copiedFrom, theKey: gameID } = game
    return !!(
      userUnlockedGames.value?.[originalGameID] ||
      userUnlockedGames.value?.[copiedFrom] ||
      userUnlockedGames.value?.[gameID]
    )
  }

  function isRoomVisitedByUser({ copiedFrom, theKey: gameID }) {
    if (userPlayedGames.value?.[copiedFrom]) return true
    if (userPlayedGames.value?.[gameID]) return true
    return false
  }

  return {
    toggleUpsideDown,
    isRoomUnlockedByUser,
    isRoomVisitedByUser,
    hasDiyRooms,
    hasVideoRooms,
    hasStandardRooms,
    videoRooms,
    diyRooms,
    selectedGame,
    standardRooms,
    userGameID,
    sortedGames
  }
}
