var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    {
      attrs: { "d-flex": "" },
      on: {
        dblclick: _vm.editGame,
        click: function ($event) {
          if (
            $event.ctrlKey ||
            $event.shiftKey ||
            $event.altKey ||
            $event.metaKey
          ) {
            return null
          }
          return _vm.onViewGame.apply(null, arguments)
        },
      },
    },
    [
      _c("div", { staticClass: "room-card", class: _vm.gameCardOutline }, [
        _c(
          "div",
          { staticClass: "room-card__top" },
          [
            _vm.streamUrl || _vm.isProccessingStream
              ? _c(
                  "v-icon",
                  {
                    staticClass: "broadcast-indicator",
                    class: {
                      "broadcast-indicator--red":
                        _vm.isProccessingStream && !_vm.streamUrl,
                    },
                  },
                  [_vm._v(" fiber_manual_record ")]
                )
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "room-card-image",
                class: _vm.cardHeadClass,
                style: _vm.cardBodyStyles,
              },
              [
                _vm.isHost && _vm.game.deactivate
                  ? _c("svg-icon", {
                      staticStyle: {
                        position: "absolute",
                        "background-color": "#c00d0d",
                        padding: "2px",
                      },
                      attrs: {
                        fill: "red",
                        height: "24",
                        width: "24",
                        name: "times-regular",
                      },
                    })
                  : _vm._e(),
                _vm.isHost && _vm.game.ondeck
                  ? _c("svg-icon", {
                      staticStyle: {
                        position: "absolute",
                        left: "24px",
                        "background-color": "#6e69d1",
                        padding: "2px",
                      },
                      attrs: {
                        fill: "red",
                        height: "24",
                        width: "24",
                        name: "calendar-alt-light",
                      },
                    })
                  : _vm._e(),
                _vm.isHost
                  ? [
                      _c(
                        "div",
                        {
                          staticClass: "room-card-title",
                          on: {
                            click: [
                              function ($event) {
                                if (!$event.shiftKey) {
                                  return null
                                }
                                return _vm.toggleDectivate.apply(
                                  null,
                                  arguments
                                )
                              },
                              function ($event) {
                                if (!$event.altKey) {
                                  return null
                                }
                                return _vm.toggleOndeck.apply(null, arguments)
                              },
                            ],
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.gameTitle) + " ")]
                      ),
                    ]
                  : [
                      _c("div", { staticClass: "room-card-title" }, [
                        _vm._v(" " + _vm._s(_vm.gameTitle) + " "),
                      ]),
                    ],
                _vm.isStandard && !_vm.isHostless && !_vm.isTwilioBroadcast
                  ? _c("div", {
                      staticClass: "room-card-host",
                      class: { "room-card-host--actual": _vm.actualHost },
                      style: _vm.isDefaultHostImage
                        ? _vm.hostImageDefault
                        : _vm.hostImageStyles,
                    })
                  : _vm._e(),
              ],
              2
            ),
          ],
          1
        ),
        !_vm.isTwilioBroadcast
          ? _c("div", { staticClass: "room-card__bottom" }, [
              _c("div", { staticClass: "room-card__description" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.game.shortDescription || _vm.game.description) +
                    " "
                ),
              ]),
              !_vm.isVideo
                ? _c("div", { staticClass: "room-card__players" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.nOfPlayersOnline) +
                        " of " +
                        _vm._s(_vm.game.players) +
                        " "
                    ),
                  ])
                : _vm._e(),
              _c(
                "div",
                { staticClass: "room-card__start-time" },
                [
                  _c("GameTime", {
                    attrs: {
                      game: _vm.game,
                      time: _vm.time,
                      gameStartTime: _vm.gameStartTime,
                      gameEndTime: _vm.gameEndTime,
                      isGameBooked: _vm.isGameBooked,
                      isSelected: _vm.isSelected,
                      isPlayedByUser: _vm.isPlayedByUser,
                      loading: _vm.loading,
                    },
                    on: {
                      onClick: _vm.onViewGame,
                      addTimeToGame: _vm.addTimeToGame,
                      removeTimeToGame: _vm.removeTimeToGame,
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]),
      !!_vm.game && _vm.editGameDialog
        ? _c(
            "v-dialog",
            {
              attrs: { persistent: "", "no-click-animation": "", width: "60%" },
              model: {
                value: _vm.editGameDialog,
                callback: function ($$v) {
                  _vm.editGameDialog = $$v
                },
                expression: "editGameDialog",
              },
            },
            [
              _c("GameSettings", {
                attrs: { game: _vm.game },
                on: { close: _vm.closeEdit },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }