var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    { staticClass: "game-time", attrs: { shrink: "", "d-flex": "" } },
    [
      _c(
        "v-flex",
        { on: { click: _vm.changeTime } },
        [
          _vm.isGameBooked && _vm.status === "Starts in"
            ? _c("span", { staticClass: "game-time__starts-in" }, [
                _vm._v(" You will enter in "),
              ])
            : _c("span", [_vm._v(" " + _vm._s(_vm.status) + " ")]),
          _vm.status === "Starts in" && !!_vm.gameStartTime
            ? _c("PreGameCountdown", { attrs: { endTime: _vm.gameStartTime } })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }