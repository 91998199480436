// TODO: Waiting for TypeScript 4.1

enum ActionTypes {
  UPDATE_SELECTED_MEETING_USER = "updateSelectedMeetingUser",
  PUSH_USERS_TO_GAME = "GameUsers/pushUsersToGame",
  SUBSCRIBE_TO_USERS = "allusers/SUBSCRIBE_TO_USERS",
  UNSUBSCRIBE_FROM_USERS = "allusers/UNSUBSCRIBE_FROM_USERS",
  SUBSCRIBE_TO_CLIENT_GAMES = "pregame/SUBSCRIBE_TO_GAMES",
  UNSUBSCRIBE_FROM_CLIENT_GAMES = "pregame/UNSUBSCRIBE_FROM_GAMES",
  ADD_TO_FILTERS = "pregame/ADD_TO_FILTERS",
  REMOVE_FROM_FILTERS = "pregame/REMOVE_FROM_FILTERS"
}

export default ActionTypes
