import { getCurrentInstance } from "@vue/composition-api"
import type { ComponentInternalInstance } from "@vue/composition-api"

export default function useNavigator() {
  const instance = getCurrentInstance() as ComponentInternalInstance & {
    proxy: {
      $navigator: { navigateTo: (route: string) => void }
    }
  }

  async function navigateTo(route: string) {
    return instance.proxy.$navigator.navigateTo(route)
  }

  return { navigateTo }
}
