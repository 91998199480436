import { computed } from "@vue/composition-api"

import { User } from "@/types/user"
import GetterTypes from "@/store/getter-types"

import useStore from "./useStore"

export default function useClientUsers() {
  const { store } = useStore()

  const users = computed<User[]>(() => store.state["allusers/users"])
  const onlineUsers = computed<User[]>(
    () => store.state[GetterTypes.ONLINE_CLIENT_USERS]
  )
  const onlineUsersGroupedByGameID = computed<Record<string, User[]>>(
    () => store.state[GetterTypes.ONLINE_CLIENT_USERS_GROUPED_BY_GAME_ID]
  )

  return { users, onlineUsers, onlineUsersGroupedByGameID }
}
