import { computed, onMounted, onBeforeUnmount } from "@vue/composition-api"

import { Game } from "@/types/game"

import useStore from "./useStore"

export default function useClientGames() {
  const { store } = useStore()

  const games = computed<Game[]>(() => store.state.pregame.games)

  function getGameByID(gameID: string) {
    console.log("games", games)
    return games.value.find(game => game.id === gameID)
  }

  return { games, getGameByID }
}
