import { GameType } from "@/entities/GameType"
import { computed, ref, watch } from "@vue/composition-api"
import useStore from "@/use/useStore"

import { setKeynoteVisited } from "@/services/localstorage.service"
import useClientGames from "./useClientGames"

const selectedGameID = ref()
const isDefaultKeynoteViewed = ref()
const showEditEvent = ref(false)
const showActivityFeed = ref(false)
const showAuditor = ref(false)
const returnToKeynote = ref(false)
const isGameVideoVisible = ref(false)
const noAnnouncement = ref(false)
const selectedUserID = ref(null)
const isBroadcastHidden = ref(false)

export default function useLobby() {
  const { store } = useStore()

  const { getGameByID, games } = useClientGames()
  const isHost = computed(() => store.getters["auth/isHost"])
  const client = computed(() => store.getters["auth/client"])

  const streamId = computed(() => client.value?.stream?.roomID)

  const isChimeStream = computed(() => {
    const gameIds = games.value.map(game => game.id)
    return gameIds.includes(streamId.value)
  })


  const isChimeStreamSelected = computed(
    () => isChimeStream.value && selectedGameID.value === streamId.value
  )
  
  const isChimeStreamActive = computed(
    () => isChimeStreamSelected.value && !isBroadcastHidden.value
  )

  const isTwilioLobbyStream = computed(() =>
    isHost.value && isChimeStream.value
      ? isChimeStreamActive.value
      : !!streamId.value
  )
  const defaultKeynoteID = computed(() => {
    const keynote = getDefaultKeynote(games.value)
    return keynote && !isDefaultKeynoteViewed.value ? keynote.theKey : null
  })
  const actualSelectedGameID = computed(() => {
    if (isHost.value) {
      return selectedGameID.value
    }

    if (isTwilioLobbyStream.value) {
      return ``
    }

    return defaultKeynoteID.value || selectedGameID.value
  })

  function isOurVideo(game) {
    return !!(game && game.gameType === GameType.OurVideo)
  }
  function isTwilioStreaming(game) {
    return !!(game && game.stream)
  }
  function isYouTube(game) {
    return !!(game && game.gameType === GameType.YouTube)
  }
  function isTwitch(game) {
    return !!(game && game.gameType === GameType.Twitch)
  }
  function isStandard(game) {
    return (
      game &&
      (!game.gameType ||
        [GameType.GreenRoom, GameType.Standard].includes(game.gameType))
    )
  }

  function isVideo(game) {
    return (
      isTwilioStreaming(game) ||
      isYouTube(game) ||
      isTwitch(game) ||
      isOurVideo(game)
    )
  }

  function isImage(game) {
    return game && game.gameType === GameType.Image
  }

  function getDefaultKeynote(games) {
    return games.find(
      ({ defaultKeynote, deactivate, ondeck, deletedTimestamp }) =>
        defaultKeynote && !deactivate && !ondeck && !deletedTimestamp
    )
  }

  async function selectGameCard({ gameID, scroll }) {
    showEditEvent.value = false
    showActivityFeed.value = false
    showAuditor.value = false

    const nextGame = getGameByID(gameID)
    // this check allow us to prevent return to keynote
    // if not broadcast game was selected after broadcast
    if (!nextGame?.streamUrl) {
      returnToKeynote.value = false
    }
    if (defaultKeynoteID.value) {
      // don't set keynote as viewed if next game is broadcast
      if (!nextGame?.streamUrl) {
        setKeynoteVisited(defaultKeynoteID.value)
      } else {
        returnToKeynote.value = true
      }
    } else if (scroll) {
      selectedUserID.value = null
    }
    selectGame({ gameID })
  }

  function selectGame({ gameID }) {
    console.log("selectGame", gameID)
    selectedGameID.value = gameID
  }

  function onVideoDrawer() {
    isGameVideoVisible.value = true
  }

  watch(defaultKeynoteID, v => {
    if (v) {
      selectedGameID.value = v
    }
  })

  return {
    isTwilioLobbyStream,
    isVideo,
    isImage,
    isStandard,
    selectGame,
    onVideoDrawer,
    selectGameCard,
    getDefaultKeynote,
    showAuditor,
    showEditEvent,
    noAnnouncement,
    selectedGameID,
    selectedUserID,
    returnToKeynote,
    showActivityFeed,
    defaultKeynoteID,
    isGameVideoVisible,
    actualSelectedGameID,
    isDefaultKeynoteViewed,
    isChimeStreamSelected,
    isChimeStream,
    isBroadcastHidden
  }
}
