













import { mapGetters } from "vuex"

import { createTimeObject } from "@/utils/time"

import { TimeObjectType } from "@/types/time"

export default {
  name: "PreGameCountdown",
  props: {
    endTime: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapGetters({ globalTime: "time" }),
    difference(): number {
      return this.endTime - this.globalTime
    },
    time(): number {
      return this.difference > 0 ? this.difference : 0
    },
    object(): TimeObjectType {
      return createTimeObject(this.time)
    }
  }
}
