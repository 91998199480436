<template>
  <v-flex shrink d-flex class="game-time">
    <v-flex @click="changeTime">
      <span
        v-if="isGameBooked && status === 'Starts in'"
        class="game-time__starts-in"
      >
        You will enter in
      </span>
      <span v-else>
        {{ status }}
      </span>
      <PreGameCountdown
        :endTime="gameStartTime"
        v-if="status === 'Starts in' && !!gameStartTime"
      />
    </v-flex>
  </v-flex>
</template>

<style lang="scss"></style>

<script>
import PreGameCountdown from "@/components/PreGameCountdown"
import { mapGetters } from "vuex"
import { Role } from "@/helpers"
export default {
  name: "newButton",
  components: {
    PreGameCountdown
  },
  props: {
    game: Object,
    time: Number,
    gameStartTime: Number,
    gameEndTime: Number,
    isGameBooked: Boolean,
    isSelected: Boolean,
    isPlayedByUser: Boolean,
    loading: {
      type: Boolean
    }
  },
  data() {
    return {
      isGameTime: false
    }
  },
  methods: {
    changeTime(event) {
      if (!this.isPlayedByUser && !this.isGameEnded) {
        if (event.altKey || event.ctrlKey) {
          this.$emit("removeTimeToGame")
        }
        if (event.shiftKey) {
          this.$emit("addTimeToGame")
        }
      }
    },
    onClick() {
      this.$emit("onClick")
    }
  },
  watch: {
    globalTime: {
      handler(value) {
        const isGameTime = value > this.gameStartTime && !!this.gameStartTime
        if (this.isGameTime !== isGameTime) this.isGameTime = isGameTime
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters({ globalTime: "time" }),
    ...mapGetters("auth", ["role"]),
    isGameEnded() {
      return !!this.gameEndTime
    },
    isBoarding() {
      return this.isGameTime && !this.game.started
    },
    status() {
      if (this.isPlayedByUser) {
        return "Played"
      } else if (this.isGameEnded) {
        return "Ended"
      } else if (this.isBoarding) {
        return "Boarding"
      } else if (this.isGameTime) {
        return "In Progress"
      } else {
        return "Starts in"
      }
    }
  }
}
</script>
