



































































































// @ts-nocheck
import { mapGetters, mapActions } from "vuex"
import moment from "moment"
import User from "@shared/User"

import { db } from "@/firebase"
import GameTime from "@/components/Lobby/GameTime.vue"
const GameSettings = () => import("@/components/Game/GameSettings.vue")
import { Role, Time } from "@/helpers"

import useClientGames from "@/use/useClientGames"
import useLobby from "@/use/useLobby"

const SELECT_RETRY_TIMEOUT = 10000 // 10 sec
const PROFILE_ICON_URL = require("@/assets/profile-icon.svg")

export default {
  name: "GameCard",
  components: {
    GameTime,
    GameSettings
  },
  props: {
    game: {
      type: Object,
      required: true
    },
    selected: String,
    isPlayedByUser: Boolean
  },
  setup() {
    const { getGameByID } = useClientGames()
    const { selectGameCard } = useLobby()
    return { getGameByID, selectGameCard }
  },
  data() {
    return {
      profileIconUrl: PROFILE_ICON_URL,
      assignedHost: null,
      time: null,
      startTime: null,
      editGameDialog: false,
      loading: false,
      loadingTimeout: null,
      users: {}
    }
  },
  beforeDestroy() {
    clearTimeout(this.loadingTimeout)
    this.assignedHostRef?.off("value", this.onHostUpdate)
    this.usersRef?.off("value", this.onUsersUpdate)
  },
  methods: {
    ...mapActions("Games", ["updateGameAny"]),
    onHostUpdate(snapshot) {
      this.assignedHost = snapshot.val()
    },
    onUsersUpdate(snapshot) {
      this.users = snapshot.val() || {}
    },
    toggleDectivate() {
      const obj = {}
      obj.theKey = this.game.id
      obj.deactivate = !this.game.deactivate
      this.updateGameAny(obj)
    },
    toggleOndeck() {
      const obj = {}
      obj.theKey = this.game.id
      obj.ondeck = !this.game.ondeck
      this.updateGameAny(obj)
    },
    closeEdit() {
      this.editGameDialog = false
    },
    editGame() {
      if (!this.isHost) return
      this.editGameDialog = true
    },
    addTimeToGame() {
      if (!this.isHost) return
      const MINUTES_TO_ADD = 5 * 1000 * 60
      const game = this.getGameByID(this.game.id)
      this.$store.dispatch("Games/updateGame", {
        ...game,
        theKey: this.game.id,
        started: false,
        gameTime: game.gameTime + MINUTES_TO_ADD,
        startTimestamp: game.startTimestamp + MINUTES_TO_ADD
      })
    },
    removeTimeToGame() {
      if (!this.isHost) return
      const MINUTES_TO_REMOVE = 1000 * 60
      const game = this.getGameByID(this.game.id)

      this.$store.dispatch("Games/updateGame", {
        ...game,
        theKey: this.game.id,
        started: false,
        gameTime: game.gameTime - MINUTES_TO_REMOVE,
        startTimestamp: game.startTimestamp - MINUTES_TO_REMOVE
      })
    },
    onUserSelect({ userID }) {
      this.$emit("onUserSelect", { userID })
    },
    onViewGame() {
      if (this.isSelected === false) {
        this.selectGameCard({ gameID: this.game.id })

        if (this.loading === false) {
          this.loading = true

          // if `isSelected` do not changes in SELECT_RETRY_TIMEOUT – reset loading state
          // to give user an ability to try again
          this.loadingTimeout = setTimeout(() => {
            this.loading = false
          }, SELECT_RETRY_TIMEOUT)
        }
      }
    }
  },
  computed: {
    ...mapGetters("auth", ["user", "isHost", "isSuper", "client"]),
    usersRef() {
      return db
        .auxiliary()
        .ref(`org/1/users`)
        .orderByChild("gameID")
        .equalTo(this.game?.id)
    },
    isTwilioBroadcast() {
      return this.game.gameType === "TwilioBroadcast"
    },
    isVideo() {
      return (
        this.game.gameType === "YouTube" ||
        this.game.gameType === "Twitch" ||
        this.game.gameType === "Our Video"
      )
    },
    actualHost() {
      return this.usersOnline.find(({ role }) => role === Role.Host)
    },
    assignedHostID() {
      return this.game?.hostUserID
    },
    assignedHostRef() {
      return this.assignedHostID
        ? db.auxiliary().ref(`org/1/users/${this.assignedHostID}`)
        : null
    },
    isGameBooked() {
      return this.user.gameID === this.game.id
    },
    gameTitle() {
      return this.game?.externalName || this.game?.name
    },
    isHostless() {
      return this.game.hostless || this.game.hostless2
    },
    isStandard() {
      return (
        !this.game.gameType ||
        this.game.gameType == "Standard" ||
        this.game.gameType == "Green Room" ||
        !!this.streamUrl
      )
    },
    /** @returns {object} */
    cardBodyStyles() {
      const { game } = this
      // Static assets take preceedance over the uploaded onces
      const preferredImage = game.gameCardImage || game.image
      const isStaticAsset = preferredImage === game.gameCardImage

      /** @type {string | undefined} */
      let url

      if (preferredImage) {
        try {
          url = isStaticAsset
            ? require(`@/assets/gamecards/${preferredImage}`)
            : preferredImage
        } catch (e) {
          console.error(
            `Failed to resolve asset ${preferredImage}. Make sure that assets exists and located under the \`@/assets/gamecards/\` folder.`
          )
        }
      }

      return preferredImage
        ? {
            "background-image": `linear-gradient(360deg, rgba(0, 0, 0, 0.6) 20%, rgba(0, 0, 0, 0) 100%), url("${url}")`
          }
        : {}
    },
    cardHeadClass() {
      if (this.shouldUsePrimaryColorAsBackground)
        return "room-card__fill-background"
      else if (this.game?.gameCardImage) return "contain-image"
      else return ""
    },
    hostImageStyles() {
      if (!this.host) return {}
      return this.host.image
        ? {
            "background-image": `url("${this.host.image}")`
          }
        : {}
    },
    hostImageDefault() {
      return { "background-image": `url("${this.profileIconUrl}")` }
    },
    gameStatus() {
      let status
      if (this.game.deletedTimestamp) {
        status = "DELETED"
      } else if (this.game.ondeck) {
        status = "STAGED"
      } else if (this.game.deactivate) {
        status = "OFF"
      } else if (this.game.endTimestamp) {
        status = "ENDED"
      }
      return status ? `(${status})` : null
    },
    gameEndTime() {
      return this.game.endTimestamp || 0
    },
    gameStartTime() {
      return this.game.startTimestamp || 0
    },
    isSelected() {
      return this.selected == this.game.id
    },
    shouldUsePrimaryColorAsBackground() {
      return Boolean(
        this.client?.primaryColorAsGameCardBackground &&
          !this.game?.gameCardImage
      )
    },
    gameCardOutline() {
      if (this.isSelected) {
        return this.shouldUsePrimaryColorAsBackground
          ? "room-card-selected room-card-selected--use-background"
          : "room-card-selected"
      } else if (this.isGameBooked) {
        return "room-card-booked"
      }
      return ""
    },
    isDefaultHostImage() {
      return !(this.host && this.host.image)
    },
    usersOnline() {
      return User.normalize(this.users).filter(this.$store.getters.isUserAlive)
    },
    playersOnline() {
      return this.usersOnline.filter(({ role }) => role === Role.Player)
    },
    nOfPlayersOnline() {
      return this.playersOnline.length
    },
    host() {
      return this.actualHost || this.assignedHost
    },
    formattedDuration() {
      return this.game.duration !== undefined
        ? Math.round(moment.duration(this.game.duration).asMinutes()) + " mins"
        : ""
    },
    streamUrl() {
      return this.game?.streamUrl
    },
    isProccessingStream() {
      return this.game?.processingStreaming ?? false
    }
  },
  watch: {
    usersRef: {
      handler(newValue, oldValue) {
        if (oldValue) {
          oldValue.off("value", this.onUsersUpdate)
          this.users = {}
        }
        if (newValue) {
          newValue.on("value", this.onUsersUpdate)
        }
      },
      immediate: true
    },
    assignedHostRef: {
      handler(newValue, oldValue) {
        if (oldValue) {
          oldValue.off("value", this.onHostUpdate)
          this.assignedHost = null
        }
        if (newValue) {
          newValue.on("value", this.onHostUpdate)
        }
      },
      immediate: true
    },
    gameStartTime: {
      handler() {
        const { time, startTime } = Time.getCountdownTimes(
          this.gameStartTime,
          this.$store.getters.time
        )
        this.time = time
        this.startTime = startTime
      },
      immediate: true
    },
    isSelected(value) {
      if (value === true) {
        if (this.loadingTimeout !== null) {
          clearTimeout(this.loadingTimeout)
          this.loadingTimeout = null
        }
        this.loading = false
      }
    }
  }
}
