import { computed, ref, watch } from "@vue/composition-api"

import { Time, Role } from "@/helpers"
import { GameType } from "@/entities/GameType"
import { MAX_PEOPLE_IN_GAME } from "@/config"
import EntryPage from "@/enums/EntryPage"
import { canUserJoinGame } from "@/services/game.service"

import useInfo from "./useInfo"
import useSnackbar from "./useSnackbar"
import useLobbyRooms from "./useLobbyRooms"
import useStore from "./useStore"
import useNavigator from "./useNavigator"
import useViewer from "./useViewer"
import useSelectedRoomUsers from "./useSelectedRoomUsers"
import useClientGames from "./useClientGames"
import { format } from "date-fns"

const STATUSES = {
  AVAILABLE: "available",
  LOCKED: "locked",
  PLAYED: "played",
  FULL: "full"
}

export default function useLobbyRoom() {
  const isGameTime = ref(false)
  const startTime = ref(null)
  const loading = ref(false)
  const { store } = useStore()
  const { viewer } = useViewer()
  const {
    selectedGame: game,
    isRoomVisitedByUser,
    isRoomUnlockedByUser
  } = useLobbyRooms()
  const { info } = useInfo()
  const { snackbar } = useSnackbar()
  const { navigateTo } = useNavigator()
  const { getGameByID } = useClientGames()
  const { nOfRoomPlayers, roomUsers, roomHost, roomUsersToShow } =
    useSelectedRoomUsers()
  const isMobile = computed(() => store.getters["Mobile/mobile"])
  const name = computed(() => game.value?.externalName || game.value?.name)
  const time = computed(() => store.getters.time)
  const orgID = computed(() => store.getters.orgID)
  const isHost = computed(() => store.getters["auth/isHost"])
  const isAudit = computed(() => store.getters["auth/isAudit"])
  const isPlayer = computed(() => store.getters["auth/isPlayer"])
  const client = computed(() => store.getters["auth/client"])
  const clientID = computed<string>(() => store.getters["auth/clientID"])
  const isSpectator = computed(() => viewer.value.role === Role.Spectator)
  const access = computed(() => store.getters["pregame/access"])
  const description = computed(() => game.value?.description)
  const gameEndTime = computed(() => game.value?.endTimestamp || 0)
  const isGameEnded = computed(() => !!gameEndTime.value)
  const gameStartTime = computed(() => game.value?.startTimestamp || 0)
  const isGameBooked = computed(() => viewer.value?.gameID === game.value?.id)
  const duration = computed(() => {
    const duration = game.value?.duration
    return (duration && `${Math.round(duration / 60000)} mins`) || ""
  })
  const nOfMaxPlayers = computed(() => game.value.players || 0)

  const isVisitedByUser = computed(() => isRoomVisitedByUser(game.value))
  const isUnlockedByUser = computed(() => isRoomUnlockedByUser(game.value))
  const isGameSpectable = computed(
    () => !!game.value.auditable && canShowBigButton.value
  )
  const canShowBigButton = computed(
    () => !isGameBooked.value && access.value < 2
  )
  const isLockedForUser = computed(
    () => !isUnlockedByUser.value && game.value.escapeRoom
  )

  const isGameCompletelyFull = computed(
    () => MAX_PEOPLE_IN_GAME <= nOfRoomPlayers.value
  )
  const status = computed(() => {
    if (isHost.value || isAudit.value) {
      return STATUSES.AVAILABLE
    }

    if (isGameEnded.value || game.value.gameType === GameType.GreenRoom) {
      return null
    }

    if (isLockedForUser.value) {
      return STATUSES.LOCKED
    }

    if (isVisitedByUser.value) {
      return STATUSES.PLAYED
    }

    if (isGameCompletelyFull.value) {
      return STATUSES.FULL
    }

    if (
      (!isVisitedByUser.value || game.value.hostless || game.value.hostless2) &&
      (!game.value.tournamentID || game.value.assignable)
    ) {
      return STATUSES.AVAILABLE
    }

    return null
  })

  const isEnterable = computed(
    () =>
      isHost.value ||
      ((isAudit.value ||
        isSpectator.value ||
        access.value === 0 ||
        isGameBooked.value) &&
        !!isGameTime.value)
  )
  const isLeaveable = computed(() => {
    if (!isHost.value && game.value.tournamentID) {
      return false
    }
    return (
      access.value === 0 ||
      ((isAudit.value || isSpectator.value) && !!isGameTime.value)
    )
  })

  const headStyle = computed(() => {
    // Static assets take preceedance over the uploaded onces
    const preferredImage =
      client.value.newLobbyCustomLogo ||
      game.value.gameCardImage ||
      game.value.image
    const isStaticAsset = preferredImage === game.value.gameCardImage

    /** @type {string | undefined} */
    let url

    if (preferredImage) {
      try {
        url = isStaticAsset
          ? require(`@/assets/gamecards/${preferredImage}`)
          : preferredImage
      } catch (e) {
        console.error(
          `Failed to resolve asset ${preferredImage}. Make sure that assets exists and located under the \`@/assets/gamecards/\` folder.`
        )
      }
    }
    if (client.value.newLobbyCustomLogo) {
      return { "background-image": `url("${url}")` }
    } else {
      return preferredImage
        ? {
            "background-image": `linear-gradient(360deg, rgba(0, 0, 0, 0.6) 20%, rgba(0, 0, 0, 0) 100%), url("${url}")`
          }
        : {}
    }
  })

  async function tryToJoin({ audit }) {
    if (loading.value) return

    loading.value = true
    if (audit && isPlayer.value) {
      await store.dispatch("updateUser", {
        userID: viewer.value.id,
        obj: { role: Role.Spectator, teamID: 0 }
      })
    } else if (isSpectator.value) {
      const message = await canUserJoinGame({
        role: viewer.value.role,
        userID: viewer.value.id,
        gameID: game.value.id,
        orgID: orgID.value
      })

      if (message) {
        throw new Error(message)
      }

      await store.dispatch("updateUser", {
        userID: viewer.value.id,
        obj: { role: Role.Player }
      })
    }

    if (isGameTime.value) {
      onJoinGame()
    } else {
      onBookGame()
    }
  }

  async function initializeToGame() {
    await store.dispatch("auth/initializeToGame", {
      gameID: game.value.id,
      clientID: clientID.value
    })
  }

  async function onJoinGame() {
    try {
      await initializeToGame()
    } catch (e) {
      info(e.message)
    }
  }

  async function onLeaveGame() {
    try {
      await store.dispatch("auth/deinitializeGame")
      if (viewer.value.value === Role.Spectator) {
        await store.dispatch("updateUser", {
          userID: viewer.value.id,
          obj: { role: Role.Player }
        })
      }
    } catch (e) {
      info(e.message)
    }
    loading.value = false
  }

  async function onEnterGame() {
    await store.dispatch("fetchMissions", game.value.id)
    await store.dispatch("bringBackActualGame")

    if (!getGameByID(game.value.id)) {
      return info(`Room ${game.value.id} is no longer available.`)
    }

    if (
      game.value.diy ||
      game.value?.entryPage === EntryPage.GAME ||
      game.value.started ||
      isMobile.value
    ) {
      // go to the game
      navigateTo(`/game/${clientID.value}${game.value.id}`)
      return
    }

    // go to the pickteams
    navigateTo(`/pickteams/${clientID.value}${game.value.id}`)
  }

  async function onBookGame() {
    const gameID = game.value.id
    try {
      await initializeToGame()

      if (!getGameByID(gameID)) {
        return alert(`Game ${gameID} not found`)
      }
      const name = game.value.externalName || game.value.name
      if (!isHost.value && gameStartTime.value > 0) {
        const string = format(gameStartTime.value, "h:mm aaa")

        await snackbar({
          message: `Thanks for joining ${name}. You will be escorted in at ${string}`,
          offset: 65,
          timeout: 6000
        })
      }
    } catch (e) {
      info(e.message)
    }
    loading.value = false
  }

  watch(
    gameStartTime,
    value => {
      const _isGameTime = !!(value && time.value > value)
      if (isGameTime.value !== _isGameTime) {
        isGameTime.value = _isGameTime
      }
      if (value) {
        const { startTime: newStartTime } = Time.getCountdownTimes(
          value,
          Date.now()
        )
        startTime.value = newStartTime
      }
    },
    { immediate: true }
  )

  return {
    game,
    name,
    time,
    status,
    STATUSES,
    description,
    gameStartTime,
    isHost,
    isVisitedByUser,
    nOfRoomPlayers,
    nOfMaxPlayers,
    tryToJoin,
    onJoinGame,
    onBookGame,
    onEnterGame,
    onLeaveGame,
    headStyle,
    roomHost,
    isGameBooked,
    isEnterable,
    isLeaveable,
    gameEndTime,
    startTime,
    isGameTime,
    isGameSpectable,
    canShowBigButton,
    loading,
    roomUsers,
    roomUsersToShow,
    duration
  }
}
