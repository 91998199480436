var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("RecycleScroller", {
    ref: "scroller",
    staticClass: "game-search__recycle-scroller",
    attrs: { items: _vm.games, "item-size": 140, "key-field": "id" },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (ref) {
          var item = ref.item
          return [
            _c("GameCard", {
              attrs: {
                game: item,
                selected: _vm.selectedGameID,
                isPlayedByUser: _vm.isGamePlayedByUser(item),
              },
            }),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }