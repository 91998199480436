var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _vm.object.hours.number > 9
        ? [_vm._v(" " + _vm._s(_vm.object.hours.text) + "h ")]
        : _vm.object.hours.number > 0
        ? [
            _vm._v(
              " " +
                _vm._s(_vm.object.hours.text) +
                ":" +
                _vm._s(_vm.object.minutes.text) +
                " "
            ),
          ]
        : [
            _vm._v(
              " " +
                _vm._s(_vm.object.minutes.text) +
                ":" +
                _vm._s(_vm.object.seconds.text) +
                " "
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }