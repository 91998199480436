<template>
  <RecycleScroller
    class="game-search__recycle-scroller"
    :items="games"
    :item-size="140"
    key-field="id"
    v-slot="{ item }"
    ref="scroller"
  >
    <GameCard
      :game="item"
      :selected="selectedGameID"
      :isPlayedByUser="isGamePlayedByUser(item)"
    />
  </RecycleScroller>
</template>

<script>
import GameCard from "@/components/Lobby/GameCard.vue"
import { RecycleScroller } from "vue-virtual-scroller"
import "vue-virtual-scroller/dist/vue-virtual-scroller.css"

export default {
  name: "GameSearch",
  components: {
    GameCard,
    RecycleScroller
  },
  props: {
    games: {
      type: Array,
      required: true
    },
    isGamePlayedByUser: {
      type: Function
    },
    selectedGameID: {
      type: String
    }
  },
  methods: {
    onGameCardSelectEvent(params) {
      this.$emit("onGameCardSelectEvent", params)
    }
  }
}
</script>

<style lang="scss">
.game-search {
  &__recycle-scroller {
    margin: 0;
    padding: 0;
    // direction: rtl;
    position: absolute;
    height: 100%;
    width: 100%;
    & > div {
      direction: ltr;
    }
  }
}
</style>
