import { getCurrentInstance } from "@vue/composition-api"
import type { ComponentInternalInstance } from "@vue/composition-api"

export default function useInfo() {
  const instance = getCurrentInstance() as ComponentInternalInstance & {
    proxy: {
      $snackbar: (...args: any[]) => void
    }
  }

  async function snackbar(...args) {
    instance.proxy.$snackbar(...args)
  }

  return { snackbar }
}
