// TODO: Waiting for TypeScript 4.1

export enum GetterTypes {
  ONLINE_USERS = `GameUsers/usersOnlineArray`,
  ONLINE_CLIENT_USERS = "allusers/ONLINE_USERS",
  ONLINE_CLIENT_USERS_GROUPED_BY_GAME_ID = "allusers/ONLINE_USERS_GROUPED_BY_GAME_ID",
  VOLUME = "soundeffect/volume",
  GAME_AUDIOS = "soundeffect/GAME_AUDIOS",
  USER_AUDIOS = "soundeffect/USER_AUDIOS"
}

export default GetterTypes
