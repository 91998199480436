import {
  ref,
  computed,
  watch,
  onBeforeUnmount,
  onMounted
} from "@vue/composition-api"
import { getGameUsersRef, fetchUser } from "@/services/user.service"
import User from "@shared/User"
import { Role, UserSorter } from "@/helpers"
import useStore from "./useStore"
import useLobbyRooms from "./useLobbyRooms"

export default function useSelectedRoomUsers() {
  const users = ref({})
  const assignedHost = ref(null)
  const { store } = useStore()
  const { selectedGame: room } = useLobbyRooms()
  const client = computed(() => store.getters["auth/client"])
  const viewerRole = computed(() => store.getters["auth/role"])
  const shouldHideAuditors = computed(() => client.value?.hideAuditors ?? false)
  const roomUsers = computed(() =>
    User.normalize(users.value).filter(store.getters.isUserAlive)
  )
  const roomPlayers = computed(() => {
    return roomUsers.value.filter(({ role }) => role === Role.Player)
  })
  const roomViewers = computed(() => {
    return roomUsers.value.filter(({ role }) => {
      if (role === Role.Audit) {
        if (!shouldHideAuditors.value) {
          return true
        } else {
          return [Role.Host, Role.Audit].includes(viewerRole.value)
        }
      }
      return role === Role.Spectator
    })
  })

  const roomUsersToShow = computed(() =>
    roomPlayers.value.concat(roomViewers.value).sort(UserSorter.watchersLast)
  )

  const actualHost = computed(() =>
    roomUsers.value.find(({ role }) => role === Role.Host)
  )
  const roomHost = computed(() => actualHost.value || assignedHost.value)
  const nOfRoomPlayers = computed(() => roomPlayers.value.length)
  const usersRef = computed(
    () => room.value?.id && getGameUsersRef({ gameID: room.value.id })
  )

  function onSnapshot(snapshot) {
    users.value = snapshot.val() || {}
  }

  watch(
    usersRef,
    (newValue, oldValue) => {
      oldValue?.off("value", onSnapshot)
      newValue?.on("value", onSnapshot)
    },
    { immediate: true }
  )

  onMounted(async () => {
    if (room.value.hostUserID) {
      assignedHost.value = await fetchUser({
        userID: room.value.hostUserID
      })
    }
  })

  onBeforeUnmount(() => usersRef.value?.off("value", onSnapshot))

  return {
    users,
    roomUsers,
    roomPlayers,
    roomViewers,
    roomUsersToShow,
    nOfRoomPlayers,
    roomHost
  }
}
