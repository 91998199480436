













import { computed, defineComponent } from "@vue/composition-api"
const PROFILE_ICON_URL = require("@/assets/profile-icon.svg")

export default defineComponent({
  name: "LobbyRoomHost",
  props: {
    user: {
      type: Object,
      default: undefined
    }
  },
  setup() {
    const hostImageDefault = computed(() => ({
      "background-image": `url("${PROFILE_ICON_URL}")`
    }))

    return { hostImageDefault }
  }
})
