import { getCurrentInstance } from "@vue/composition-api"
import type { ComponentInternalInstance } from "@vue/composition-api"

export default function useInfo() {
  const instance = getCurrentInstance() as ComponentInternalInstance & {
    proxy: {
      $info: (...args: any[]) => void
    }
  }

  async function info(...args) {
    instance.proxy.$info(...args)
  }

  return { info }
}
